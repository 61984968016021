







import { Component, Vue } from "vue-property-decorator";
import Title from "./components/Title.vue";
import Main from "./components/Main.vue";

@Component({
  components: {
    Title,
    Main
  }
})
export default class App extends Vue {}
