export default {
  welcome: "Bienvenidos",
  toour: "a nuestro",
  world: "mundo",
  wearrived: "Llegamos a la tierra y nos convertimos en un",
  fullservicestudio: "Full Service Studio,",
  with: "con soluciones",
  crossmedia: "Cross Media",
  forbrands:
    "para las marcas que realmente quieren<br />conquistar el universo.",
  discovernew: "Vamos a descubrir nuevas",
  galaxies: "galaxias",
  baseoperations:
    "En nuestra Base de Operaciones creamos innovadoras estrategias para conquistar el mundo digital.",
  didlearn:
    "Si algo aprendimos bien en otras galaxias es garantizar TIEMPO y CALIDAD en cada proyecto.",
  wedonthave: "<br />ya no <br />tenemos <br /> un problema",
  ourservices: "Nuestros servicios combinan",
  efficiencycreativityquality: "eficiencia, creatividad y calidad, ",
  deliveringall:
    "entregando proyectos a toda escala con tiempos fuera de este mundo.",
  techlab: "Tech Lab Development",
  appplatform: "Desarrollo de APPS y PATAFORMAS",
  webdev: "Desarrollo WEB",
  optimizationmaintenance: "Optimización y Mantenimiento",
  qualityassurance: "Aseguramiento de Calidad",
  digitalproduction: "Producción Digital",
  digitalads: "Producción de anuncios digitales",
  adaptationlocalization: "Adaptación y Localización",
  creativitydesign: "Creatividad y Diseño",
  emailproduction: "Diseño y producción de Correo Electrónico",
  qa: "Servicio de Control de Calidad",
  strategycreativity: "Estrategia y Creatividad",
  artdesign: "Diseño y Arte",
  branding: "Branding",
  digitalretouching: "Retoque Digital",
  corporatebrand: "Imagen Corporativa",
  naming: "Naming",
  mediastrategyadvertising: "Estrategia y pauta de medios",
  audiovisualproduction: "Produción Audiovisual",
  filmvideoproduction: "Producción de cine & video",
  postproduction: "Postproducción",
  a2D3D: "Animación 2D y 3D",
  motiongraphics: "Motion Graphics",
  audioproduction: "Producción de Audio",
  voiceover: "Voice-over",
  photography: "Fotografía",
  digitalmarketing: "Marketing Digital",
  strategydevelopment: "Estrategia y Desarrollo",
  contentcreation: "Creación de Contenido",
  community: "Community",
  management: "Management",
  monitoring: "Monitoring",
  paidmedia: "Paid Media",
  seosem: "SEO / SEM",
  researchanalytics: "Research and Analytics",
  helping: "Helping",
  humans: "Humans",
  socialaid: "Nuestra filosofía de ayuda social",
  betterworld:
    "Creemos firmemente que sí <br /> podemos construir un mundo mejor.",
  webelieve:
    "Creemos que todas las personas de este mundo merecen vivir mejor, y por",
  thatswhy: "eso nuestra filosofía de trabajo nos exige",
  staycommited: "comprometernos con las personas",
  greaterneed: "más necesitadas,",
  everyproject: "por lo que de cada proyecto contratado",
  weallocate: "destinamos un",
  fixedpercentage: "porcentaje fijo",
  asadonation: "para donaciones a instituciones, organizaciones o personas",
  needingsupport: "con necesidades de ayuda social para",
  achieveimproved: "lograr una mejor calidad de vida.",
  careers: "Careers",
  become: "Quiero ser<br /> NonHuman",
  freelanceseniorgraphicdesigner: "Freelance Senior Graphic Designer",
  freelancecopywritter: "Freelance Copywritter",
  seniorfullstackdeveloper: "Senior Full Stack Developer",
  seniorcommunitymanager: "Senior Community Manager",
  junioraccountassistant: "Junior Account Assistant",
  databaseanalyst: "Data Base Analyst",
  readylaunch: "¿Listos para <br />despegar?",
  presentation: "Presentación NonHuman"
};
