import Vue from "vue";
import { i18n } from "../plugins/i18n";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "index",
    beforeEnter(to, from, next) {
      // lang
      const locale = navigator.language;
      const lang = locale.split("-")[0];

      next(`/${lang}`);
    }
  },
  {
    path: "/:lang",
    name: "Home",
    component: Home,
    beforeEnter(to, from, next) {
      const lang = to.params.lang;
      if (i18n.locale !== lang) i18n.locale = lang;
      return next();
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
