export default {
  welcome: "Welcome",
  toour: "to our",
  world: "world",
  wearrived: "We arrived on Earth and became a",
  fullservicestudio: "Full Service Studio,",
  with: "with",
  crossmedia: "Cross Media Solutions",
  forbrands: "for brands who really want<br /> to conquer the Universe.",
  discovernew: "Let's discover new",
  galaxies: "galaxies",
  baseoperations:
    "On our base of operations we create innovative strategies to conquer the digital world.",
  didlearn:
    "Something we did learn in other galaxies is to guarantee TIME (DEADLINES) and QUALITY for each project.",
  wedonthave: "</br>we don't </br>have a </br>problem",
  ourservices: "Our services combine",
  efficiencycreativityquality: "efficiency, creativity, and quality,",
  deliveringall:
    " — delivering all-size projects within out of this world deadlines.",
  techlab: "Tech Lab Development",
  appplatform: "App and Platform Development",
  webdev: "Web Development",
  optimizationmaintenance: "Optimization and Maintenance",
  qualityassurance: "Quality Assurance",
  digitalproduction: "Digital Production",
  digitalads: "Digital Ads Production",
  adaptationlocalization: "Adaptation and Localization",
  creativitydesign: "Creativity and Design",
  emailproduction: "Email design and production",
  qa: "Quality assurance service",
  strategycreativity: "Strategy and Creativity",
  artdesign: "Art and Design",
  branding: "Branding",
  digitalretouching: "Digital Retouching",
  corporatebrand: "Corporate Brand",
  naming: "Naming",
  mediastrategyadvertising: "Media Strategy and Advertising",
  audiovisualproduction: "Audiovisual Production",
  filmvideoproduction: "Film and Video Production",
  postproduction: "Post-production",
  a2D3D: "2D and 3D Animation",
  motiongraphics: "Motion Graphics",
  audioproduction: "Audio Production",
  voiceover: "Voice-over",
  photography: "Photography",
  digitalmarketing: "Digital Marketing",
  strategydevelopment: "Strategy and development",
  contentcreation: "Content creation",
  community: "Community",
  management: "Management",
  monitoring: "Monitoring",
  paidmedia: "Paid Media",
  seosem: "SEO / SEM",
  researchanalytics: "Research and Analytics",
  helping: "Helping",
  humans: "Humans",
  socialaid: "Our social aid philosophy",
  betterworld: "We strongly believe that<br /> we can create a better world.",
  webelieve:
    "We believe that every person in the world deserves to live better.",
  thatswhy: "That is why our work philosophy urges us to",
  staycommited: "",
  greaterneed: "stay committed to the ones in greater need,",
  everyproject: "—",
  weallocate: "",
  fixedpercentage: "we allocate a fixed percentage",
  asadonation:
    "as a donation from every signed project towards institutions, organizations or people needing social support",
  needingsupport: "",
  achieveimproved: "to achieve an improved life quality.",
  careers: "Careers",
  become: "I want to become <br />a NonHuman",
  freelanceseniorgraphicdesigner: "Freelance Senior Graphic Designer",
  freelancecopywritter: "Freelance Copywritter",
  seniorfullstackdeveloper: "Senior Full Stack Developer",
  seniorcommunitymanager: "Senior Community Manager",
  junioraccountassistant: "Junior Account Assistant",
  databaseanalyst: "Data Base Analyst",
  readylaunch: "Are You Ready <br />for Launch?",
  presentation: "NonHuman Presentation"
};
